import React, { useState, useEffect } from "react";
import { firestore } from "./firebase"; // Import Firestore instance
import { doc, getDoc, updateDoc } from "firebase/firestore"; // Firestore methods

const PlayerCount = () => {
  const [playerCount, setPlayerCount] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false); // Track if the count was already updated

  const documentPath = "playerCount/playerCount"; // Firestore document path

  // Fetch player count from Firestore
  const fetchPlayerCount = async () => {
    try {
      const docRef = doc(firestore, documentPath); // Reference to Firestore document
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPlayerCount(docSnap.data().playerCount); // Assuming 'playerCount' is the field name
      } else {
        console.error("Player count document does not exist.");
      }
    } catch (error) {
      console.error("Error fetching player count:", error);
    }
  };

  // Update player count in Firestore
  const updatePlayerCount = async () => {
    try {
      const docRef = doc(firestore, documentPath);
      const newCount = (playerCount || 0) + 1;

      await updateDoc(docRef, { playerCount: newCount }); // Update the 'playerCount' field
      setPlayerCount(newCount); // Update state with the new count
      setIsUpdated(true); // Mark count as updated
    } catch (error) {
      console.error("Error updating player count:", error);
    }
  };

  // Fetch player count on component mount
  useEffect(() => {
    fetchPlayerCount();
  }, []);

  // Update player count after fetching it, only once
  useEffect(() => {
    if (playerCount !== null && !isUpdated) {
      updatePlayerCount();
    }
  }, [playerCount, isUpdated]);

  return (
    <div style={{ textAlign: "center", margin: "20px" }}>
      <div className="fs-5 mt-2" style={{ color: "#F5F704" }}>Visited Players</div>
      <div className="" style={{ color: "white" }}>
        {playerCount !== null ? playerCount : "Loading..."}
      </div>
    </div>
  );
};

export default PlayerCount;
