// Import the necessary Firebase functions
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCH2DiKYQac4ihoPXnyq8QGbKnCTcgsjkE",
//   authDomain: "hnbsantabrigade.firebaseapp.com",
//   projectId: "hnbsantabrigade",
//   storageBucket: "hnbsantabrigade.appspot.com", // Fixed the storageBucket URL
//   messagingSenderId: "604853757852",
//   appId: "1:604853757852:web:6b4450bd3505e2ac4f8a59",
//   measurementId: "G-SX376EDR8D",
// };

const firebaseConfig = {
  apiKey: "AIzaSyADmxpR_L_frUVxsCRYBfFR6YRkl4qGWeQ",
  authDomain: "hnbsantabrigade2.firebaseapp.com",
  projectId: "hnbsantabrigade2",
  storageBucket: "hnbsantabrigade2.firebasestorage.app",
  messagingSenderId: "280514389643",
  appId: "1:280514389643:web:a401251f837c1c666b568e",
  measurementId: "G-XKFT3DSYFX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics (optional, for tracking)
const analytics = getAnalytics(app);

// Initialize Firestore
const firestore = getFirestore(app);

// Export initialized services for use in other files
export { app, analytics, firestore };
